// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VImageSrcSet_A-A8u img{display:block;height:100%;object-fit:cover;width:100%}.VImageSrcSet_A-A8u._contain_GO8rd img{object-fit:contain}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-mobile": "56px",
	"footer-h": "61.2rem",
	"mobile": "0",
	"tablet-sm": "768px",
	"tablet": "1024px",
	"laptop": "1280px",
	"desktop": "1440px",
	"large-desktop": "1920px",
	"VImageSrcSet": "VImageSrcSet_A-A8u",
	"_contain": "_contain_GO8rd"
};
module.exports = ___CSS_LOADER_EXPORT___;
